import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const Link = ({ link, className, target, rel, children, id, title }) => {
  if (
    !link ||
    (typeof link === 'object' &&
      link.url?.length === 0 &&
      link.cached_url?.length === 0)
  ) {
    return <span className={className}>{children}</span>
  }

  let linkUrl = ''
  if (typeof link === 'object' && link.cached_url?.length > 0) {
    linkUrl = `${link.linktype && link.linktype === 'story' ? '/' : ''}${
      link.cached_url
    }`
  } else if (typeof link === 'string') {
    linkUrl = link
  } else {
    linkUrl = ''
  }

  if (linkUrl.indexOf('@') !== -1 && linkUrl.indexOf('/') === -1) {
    return (
      <a
        className={className}
        href={(linkUrl.indexOf('mailto:') === -1 ? 'mailto:' : '') + linkUrl}
        onClick={(e) => {
          onClick(e)
        }}
      >
        {children}
      </a>
    )
  }

  // catch internal links that are set like an external link
  let currentOrigin
  if (typeof window !== 'undefined') {
    currentOrigin = window.location.origin
  }

  let linkTarget
  if (
    (link.linktype && link.linktype === 'story') ||
    (link.cached_url?.length > 0 && link.cached_url.indexOf('#') === 0) ||
    (link.cached_url?.length > 0 &&
      currentOrigin?.length > 0 &&
      link.cached_url.indexOf(currentOrigin) !== -1) ||
    (typeof link === 'string' &&
      link.indexOf('www') === -1 &&
      link.indexOf('http') === -1)
  ) {
    linkTarget = target
  } else {
    // force target=_blank for all other domains
    linkTarget = '_blank'
  }

  if (
    linkUrl.length < 1 ||
    linkUrl.indexOf('www') !== -1 ||
    linkUrl.indexOf('http') !== -1 ||
    linkTarget === '_blank'
  ) {
    return (
      <a
        className={className}
        href={linkUrl}
        target={linkTarget}
        rel={rel}
        id={id}
        title={title}
      >
        {children}
      </a>
    )
  } else {
    return (
      <GatsbyLink className={className} to={linkUrl} id={id ? id : ''}>
        {children}
      </GatsbyLink>
    )
  }
}

export default Link
