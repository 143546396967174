import React, { Fragment } from 'react'
import Link from '../atoms/Link'
import { displayNavItem } from '../../utils/partner_portal_spa'

const HoverNavCol = ({ items }) => {
  return items.map((item, index) => (
    <Fragment key={index}>
      <Link className="nav-main-category-link" link={item.path}>
        <span>{item.title}</span>
        {item?.description?.length > 0 && item.nav_link?.length === 0 && (
          <span className="nav-main-category-link-description">
            {item.description}
          </span>
        )}
      </Link>
      {item.nav_link?.length > 0 && (
        <ul className="nav flex-column">
          {item.nav_link.map(
            (navItem, index) =>
              displayNavItem(navItem) && (
                <li className="nav-item" key={index}>
                  {navItem.alternate_link_markup ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: navItem.alternate_link_markup,
                      }}
                    />
                  ) : (
                    <Link className="nav-link" link={navItem.path}>
                      <span>
                        {navItem.strong == true && (
                          <strong>{navItem.title}</strong>
                        )}
                        {navItem.strong != true && <>{navItem.title}</>}
                      </span>
                    </Link>
                  )}
                </li>
              )
          )}
        </ul>
      )}
    </Fragment>
  ))
}

export default HoverNavCol
