import React, { Fragment } from 'react'

import Link from '../atoms/Link'
import Image from '../atoms/Image'

import LogoGdc from '../../assets/images/rff-mo-sw.png'
import LogoIba from '../../assets/images/iba-logo-small.png'
import LogoEintracht from '../../assets/images/ef-partner-logo.png'
import DealerSearch from '../modules/DealerSearch'

import { useTranslation } from 'react-i18next'

const Footer = ({ navData, locale, noDealerSearch }) => {
  const { t } = useTranslation()

  const startYear = 2018
  const currentYear = new Date().getFullYear()

  return (
    <footer className="multi-section-footer">
      <section className="footer-main-section">
        <div className="footer-container">
          <div className="footer-wrapper">
            <div className="row">
              {navData &&
                navData?.map(
                  (item, index) =>
                    item.component === 'main_nav_block' && (
                      <div className="col-12 col-lg-9" key={index}>
                        <div className="row">
                          <div className="col-6 col-md-4">
                            {item &&
                              item?.main_nav_group.map(
                                (item, index) =>
                                  item.col === 'left' && (
                                    <Fragment key={index}>
                                      <span className="h4">
                                        <Link link={item.path}>
                                          {item.title}
                                        </Link>
                                      </span>
                                      <ul className="list-unstyled">
                                        {item.nav_link.map((item, index) => (
                                          <li key={index}>
                                            <Link link={item.path}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Fragment>
                                  )
                              )}
                          </div>
                          <div className="col-6 col-md-4">
                            {item &&
                              item?.main_nav_group.map(
                                (item, index) =>
                                  item.col === 'middle' && (
                                    <Fragment key={index}>
                                      <span className="h4">
                                        <Link link={item.path}>
                                          {item.title}
                                        </Link>
                                      </span>
                                      <ul className="list-unstyled">
                                        {item.nav_link.map((item, index) => (
                                          <li key={index}>
                                            <Link link={item.path}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Fragment>
                                  )
                              )}
                          </div>
                          <div className="col-6 col-md-4">
                            {item &&
                              item?.main_nav_group.map(
                                (item, index) =>
                                  item.col === 'right' && (
                                    <Fragment key={index}>
                                      <span className="h4">
                                        <Link link={item.path}>
                                          {item.title}
                                        </Link>
                                      </span>
                                      <ul className="list-unstyled">
                                        {item.nav_link.map((item, index) => (
                                          <li key={index}>
                                            <Link link={item.path}>
                                              {item.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Fragment>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                    )
                )}
              <div className="col-12 col-lg-3">
                <div className="footer-contact-info-column">
                  <div data-rellax-center="true" data-rellax-speed={-1}>
                    <div className="footer-contact-info">
                      <div className="footer-contact-info-content">
                        <div className="footer-contact-info-text">
                          <p className="intro">{t('contact_space.intro')}</p>
                          <p className="title">{t('contact_space.title')}</p>
                          <p>
                            {t('contact_space.tel_link').length > 0 && (
                              <>
                                <a href={t('contact_space.tel_link')}>
                                  <i className="fas fa-phone fa-flip-horizontal" />{' '}
                                  {t('contact_space.tel')}
                                </a>
                                <br />
                              </>
                            )}
                            {t('contact_space.contact_link').length > 0 && (
                              <>
                                <Link link={t('contact_space.contact_link')}>
                                  <i className="fas fa-at" />{' '}
                                  {t('contact_space.contact')}
                                </Link>
                                <br />
                              </>
                            )}
                            {t('contact_space.town').length > 0 && (
                              <>
                                <i className="fas fa-map-marker-alt" />{' '}
                                {t('contact_space.street')}
                                <br />
                                {t('contact_space.town')}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="footer-social-media-bar">
                          <Link
                            link="https://de.linkedin.com/company/k-nig-neurath"
                            className="socialmedia-link-white"
                          >
                            <i className="fab fa-linkedin" aria-hidden="true" />
                          </Link>
                          <Link
                            link="https://www.instagram.com/koenig_neurath_ag/"
                            className="socialmedia-link-white"
                          >
                            <i
                              className="fab fa-instagram"
                              aria-hidden="true"
                            />
                          </Link>
                          <Link
                            link="https://www.facebook.com/koenig.neurath/"
                            className="socialmedia-link-white"
                          >
                            <i
                              className="fab fa-facebook-f"
                              aria-hidden="true"
                            />
                          </Link>
                          {/* <Link
                          link="https://www.xing.com/companies/k%C3%B6nig+neurathag"
                          className="socialmedia-link-white"
                        >
                          <i className="fab fa-xing" aria-hidden="true" />
                        </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {navData &&
                  navData?.map(
                    (item, index) =>
                      item.component === 'main_nav_block' && (
                        <div key={index}>
                          {item &&
                            item?.main_nav_group.map(
                              (item, index) =>
                                item.col === 'none' && (
                                  <p className="h4" key={index}>
                                    <Link link={item.path}>{item.title}</Link>
                                  </p>
                                )
                            )}
                        </div>
                      )
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Highlight Links & Socialmedia Teil */}
      <section className="footer-line">
        <div className="footer-container">
          <hr />
        </div>
      </section>
      {/* Copyright Teil */}
      <section className="footer-cr-section">
        <div className="footer-container">
          <div className="footer-wrapper">
            <div className="row footer-row footer-row-partner">
              <div className="col-12 col-lg-4">
                <Link
                  link="https://www.german-design-council.de/about/"
                  className="footer-external"
                  title="Member of Rat für Formgebung – German Design Council"
                >
                  <Image
                    alt="Member of Rat für Formgebung – German Design Council"
                    className="footer-external-image"
                    image={LogoGdc}
                  />
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link
                  link="https://iba.online"
                  className="footer-external"
                  title="iba - Industrieverband Büro und Arbeitswelt"
                >
                  <Image
                    alt="iba - Industrieverband Büro und Arbeitswelt"
                    className="footer-external-image"
                    image={LogoIba}
                  />
                </Link>
              </div>
              <div className="col-12 col-lg-4">
                <Link
                  link="https://www.eintracht.de/start/"
                  className="footer-external"
                  title="Eintracht Frankfurt Partner"
                >
                  <Image
                    alt="Eintracht Frankfurt Partner"
                    className="footer-external-image footer-external-image-ef-partner"
                    image={LogoEintracht}
                  />
                </Link>
              </div>
            </div>
            <div className="row footer-row">
              <div className="col-12">
                <ul>
                  {navData &&
                    navData?.map(
                      (item, index) =>
                        item.component === 'sub_nav_link' && (
                          <Fragment key={index}>
                            <li>
                              {item.alternate_link_markup?.length > 0 ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.alternate_link_markup,
                                  }}
                                ></div>
                              ) : (
                                <Link
                                  link={item.path}
                                  title={item.title}
                                  target="_blank"
                                >
                                  <span>{item.title}</span>
                                </Link>
                              )}
                            </li>
                            <li>
                              <span>|</span>
                            </li>
                          </Fragment>
                        )
                    )}
                </ul>
                <p>
                  © {startYear}
                  {currentYear !== startYear ? ` - ${currentYear}` : ''} König +
                  Neurath AG | Alle Rechte vorbehalten
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ENDE Copyright Teil */}
    </footer>
  )
}

export default Footer
