import React, { useState, useRef } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import useClickOutside from 'gatsby-theme-kn/src/hooks/useClickOutside'

Modal.setAppElement('#portal')

const ModalModule = ({ isOpen, closeModal, title, icon, children }) => {
  const clickOutsideRef = useRef()
  useClickOutside(clickOutsideRef, () => closeModal())

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className="spa-modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,.5)',
          zIndex: '1060',
        },
      }}
    >
      <Helmet bodyAttributes={{ class: 'overflow-hidden' }}></Helmet>
      <div
        id="spa-modal"
        className="spa-modal modal d-block"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="spa-modal-label"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" ref={clickOutsideRef}>
            <div className="modal-header">
              <h4 className="modal-title">
                {icon && <i className={icon} />}
                &nbsp;
                {title}
              </h4>
              <button
                type="button"
                className="btn btn-close"
                data-dismiss="modal"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalModule
