import { useState, useEffect } from 'react'
import axios from 'axios'
import { auth_token } from 'gatsby-theme-kn/src/utils/partner_portal_spa'

import i18next from 'i18next'
import '../i18n/config'
import { initReactI18next } from 'react-i18next'

const useFetch = (url, formData, method) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const locale = i18next.use(initReactI18next).language

  axios.interceptors.request.use(
    function (config) {
      config.headers.Authorization = auth_token()
      config.headers.Accept = 'application/vnd.kn.partnerportal.v1+json'
      return config
    },
    null,
    { synchronous: true }
  )

  useEffect(() => {
    if (url && url !== null) {
      if (url.includes('?')) {
        url = url + '&locale=' + locale
      } else {
        url = url + '?locale=' + locale
      }

      setLoading(true)
      setData(null)
      setError(null)
      if (method === 'get') {
        axios
          .get(url)
          .then((res) => {
            setLoading(false)
            setData(res.data)
          })
          .catch((err) => {
            setLoading(false)
            setError(err)
          })
      } else if (method === 'post') {
        axios
          .post(url, formData, {})
          .then((res) => {
            setLoading(false)
            setData(res.data)
          })
          .catch((err) => {
            setLoading(false)
            setError(err.response.data)
          })
      } else if (method === 'put') {
        axios
          .put(url, formData, {})
          .then((res) => {
            setLoading(false)
            setData(res.data)
          })
          .catch((err) => {
            setLoading(false)
            setError(err.response.data)
          })
      }

      return () => {
        setLoading(false)
        setData(null)
        setError(null)
      }
    }
  }, [url, locale])

  return {
    data,
    loading,
    error,
  }
}

export default useFetch
