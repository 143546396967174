import React, { useState, useEffect } from 'react'
import Link from '../atoms/Link'
import Image from '../atoms/Image'
import Logo from '../../assets/images/kn-logo.svg'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Search from './Search'
import Login from './Login'
import { displayNavItem } from '../../utils/partner_portal_spa'
import HoverNavCol from './HoverNavCol'

gsap.registerPlugin(ScrollTrigger)

const Header = ({
  navData,
  locale,
  alternates,
  searchTerm,
  loginTerm,
  mobileMenuClickHandler,
}) => {
  const [changedUserState, setChangedUserState] = useState(null)
  const [activeMenuState, setActiveMenuState] = useState(null)
  const [headerElement, setHeaderElement] = useState(null)
  const { t } = useTranslation()

  const toggleMenu = (e, index) => {
    e.preventDefault()
    const newActiveMenuState = activeMenuState !== index ? index : null
    setActiveMenuState(newActiveMenuState)
  }

  const menuBlurHandler = (e) => {
    if (e?.relatedTarget?.tagName === 'A') {
      return
    } else {
      setActiveMenuState(null)
    }
  }

  useEffect(() => {
    if (headerElement) {
      ScrollTrigger.create({
        start: 'top -40px',
        end: 99999,
        onEnter: () => {
          headerElement.classList.add('scrolled-state')
        },
        onLeaveBack: () => {
          headerElement.classList.remove('scrolled-state')
        },
      })
    }
  }, [headerElement])

  return (
    <header ref={setHeaderElement} className="header-navbar">
      <div className="header-navbar-bg-fade">
        <div className="header-navbar-wrapper">
          <div className="meta-nav">
            <nav className="navbar navbar-expand-lg meta-nav-content">
              <ul className="ms-auto nav navbar-nav">
                {alternates?.map((link, index) => {
                  return (
                    <li className="nav-item" key={index}>
                      <Link className="nav-link" link={`/${link.full_slug}`}>
                        <span>{link.lang.toUpperCase()}</span>
                      </Link>
                    </li>
                  )
                })}
                <li className="nav-item">
                  <span className="meta-nav-seperator">|</span>
                </li>
                {/* Links innerhalb der Hauptnavigation (Fachhandel, Berater,
                Presse) */}
                {navData?.length > 0 &&
                  navData?.map(
                    (item, index) =>
                      item.component === 'nav_link' && (
                        <li className="nav-item" key={index}>
                          <Link className="nav-link" link={item.path}>
                            <span>{item.title}</span>
                          </Link>
                        </li>
                      )
                  )}
                <li className="nav-item">
                  <span className="meta-nav-seperator">|</span>
                </li>
                <li className="nav-item">
                  <Login
                    activeMenuState={activeMenuState}
                    loginTerm={loginTerm}
                    t={t}
                    toggleMenu={toggleMenu}
                    changedUserState={changedUserState}
                    setChangedUserState={setChangedUserState}
                  />
                </li>
              </ul>
            </nav>
          </div>

          <div className="header-navbar-container">
            <nav className="navbar navbar-expand-lg main-nav">
              <Link link={`/${locale}/`} className="navbar-brand">
                <Image
                  alt="König + Neurath Logo"
                  className="brand-logo"
                  image={Logo}
                  forceLoad={true}
                />
                <div className="brand-title">
                  <span>König + Neurath</span>
                </div>
              </Link>
              <button
                className="navbar-toggler"
                onClick={() => mobileMenuClickHandler()}
              >
                <i className="fal fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse" id="navDataigation">
                <div className="nav navbar-nav search-nav order-2">
                  <div className="nav-item">
                    <Search searchTerm={searchTerm} />
                  </div>
                </div>
                {/* Hauptnavigationspunkte */}
                <ul className="me-auto navbar-nav">
                  {navData &&
                    navData?.map(
                      (item, index) =>
                        item.component === 'main_nav_block' &&
                        displayNavItem(item) &&
                        (item.main_nav_group?.length > 0 ? (
                          <li
                            className="nav-item"
                            data-needs-session={item.needs_session}
                            key={index}
                          >
                            <a
                              className={`nav-link has-childs ${
                                activeMenuState === index && 'active'
                              }`}
                              href={item.path}
                              data-toggle-target-menu={`#${item._uid}`}
                              onClick={(e) => toggleMenu(e, index)}
                              onBlur={(e) => menuBlurHandler(e)}
                            >
                              <span>{item.title.toUpperCase()}</span>
                              <i className="fal fa-chevron-down"></i>
                            </a>
                          </li>
                        ) : (
                          <li
                            className="nav-item"
                            data-needs-session={item.needs_session}
                            key={index}
                          >
                            <Link
                              className={`nav-link ${
                                activeMenuState === index && 'active'
                              }`}
                              link={item.path}
                            >
                              <span>{item.title.toUpperCase()}</span>
                            </Link>
                          </li>
                        ))
                    )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      {/* Hover-Menu */}
      {navData &&
        navData?.map(
          (navData, index) =>
            navData.component === 'main_nav_block' &&
            navData.main_nav_group?.length > 0 && (
              <div
                className={`main-nav-menu ${
                  activeMenuState === index && 'show'
                }`}
                id={navData._uid}
                key={index}
              >
                <div className="main-nav-menu-overlay"></div>
                <div className="main-nav-menu-container">
                  <div className="main-nav-menu-row">
                    {/* Linke Spalte */}
                    <div className="main-nav-menu-cell">
                      <div className="main-nav-menu-2nd-level">
                        <HoverNavCol
                          items={navData?.main_nav_group?.filter(function (
                            item
                          ) {
                            return item.col === 'left'
                          })}
                        />
                      </div>
                    </div>
                    {/* Mittlere Spalte */}
                    <div className="main-nav-menu-cell">
                      <div className="main-nav-menu-2nd-level">
                        <HoverNavCol
                          items={navData?.main_nav_group?.filter(function (
                            item
                          ) {
                            return item.col === 'middle'
                          })}
                        />
                      </div>
                    </div>
                    {/* Rechte Spalte */}
                    <div className="main-nav-menu-cell">
                      <div className="main-nav-menu-2nd-level">
                        <HoverNavCol
                          items={navData?.main_nav_group?.filter(function (
                            item
                          ) {
                            return item.col === 'right'
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
        )}
      {/* <nav id="mobileNav" style={{ visibility: 'hidden' }}>
        <div className="search-nav">
          <div className="search">
            <Search searchTerm={searchTerm} />
          </div>
        </div>
        <div></div>
      </nav> */}
    </header>
  )
}

export default Header
