import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import useAxiosPartnerPortal from 'gatsby-theme-kn/src/hooks/useAxiosPartnerPortal'
import axios from 'axios'
import {
  apiURL,
  getUserName,
  cookieSettings,
} from '../../utils/partner_portal_spa'
import FormData from 'form-data'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

const ModalNewPassword = (locale) => {
  const { t } = useTranslation()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [successfull, setsuccessfull] = useState(false)
  const [shouldClose, setShouldClose] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setShouldClose(true)
    setIsOpen(false)
  }

  const location = useLocation()
  const params = queryString.parse(location.search)

  const [ErrorPassword, setErrorPassword] = useState(false)
  const [ErrorPasswordConfirmation, setErrorPasswordConfirmation] =
    useState(false)

  const [PasswordFormData, setPasswordFormData] = useState({
    email: params.email,
    validation_token: params.validation_token,
    password: '',
    password_confirmation: '',
  })

  if (
    params.user_action === 'new_password' &&
    !modalIsOpen &&
    !successfull &&
    !shouldClose
  ) {
    openModal()
    return true
  }

  const Fdata = new FormData()
  const FormSubmit = (e) => {
    e.preventDefault()

    Fdata.append('email', PasswordFormData.email)
    Fdata.append('validation_token', PasswordFormData.validation_token)
    Fdata.append('password', PasswordFormData.password)
    Fdata.append(
      'password_confirmation',
      PasswordFormData.password_confirmation
    )

    axios
      .post(apiURL('auth/new_password'), Fdata, {})
      .then((result) => {
        setsuccessfull(result.data.message)
      })
      .catch((error, data) => {
        if (error.response.data.errors && error.response.data.errors.password) {
          setErrorPassword(error.response.data.errors.password[0])
        }
        if (
          error.response.data.errors &&
          error.response.data.errors.password_confirmation
        ) {
          setErrorPasswordConfirmation(
            error.response.data.errors.password_confirmation[0]
          )
        }
      })
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="spa-modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,.5)',
            zIndex: '1060',
          },
        }}
      >
        <Helmet bodyAttributes={{ class: 'overflow-hidden' }}></Helmet>
        <div
          id="spa-modal"
          className="spa-modal modal d-block"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="spa-modal-label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="spa-modal-label">
                  {t('generic.user.new_password_modal_title')}
                </h4>
                <input
                  className="btn btn-close"
                  type="button"
                  value=""
                  onClick={(e) => closeModal(e)}
                />
              </div>
              <div className="modal-body">
                <div className="spa-block-full">
                  {successfull && <p>{successfull}</p>}

                  {!successfull && (
                    <form
                      data-id="partnerportal-new-password-form"
                      onSubmit={(e) => FormSubmit(e)}
                    >
                      <div className="row form-row form-row-passwords">
                        <div className="form-label-group mb-1 col-md-6">
                          <label htmlFor="password">
                            <sup>*</sup> {t('generic.user.password')}
                          </label>
                          <input
                            placeholder={t('generic.user.password')}
                            type="password"
                            className="form-control"
                            required="true"
                            onChange={(e) =>
                              setPasswordFormData({
                                ...PasswordFormData,
                                password: e.target.value,
                              })
                            }
                          />
                          {ErrorPassword && (
                            <div
                              className="invalid-feedback"
                              style={{ display: 'block' }}
                            >
                              {ErrorPassword}
                            </div>
                          )}
                        </div>

                        <div className="form-label-group mb-1 col-md-6">
                          <label htmlFor="password_confirmation">
                            <sup>*</sup>{' '}
                            {t('generic.user.password_confirmation')}
                          </label>
                          <input
                            placeholder={t(
                              'generic.user.password_confirmation'
                            )}
                            type="password"
                            className="form-control"
                            required="true"
                            onChange={(e) =>
                              setPasswordFormData({
                                ...PasswordFormData,
                                password_confirmation: e.target.value,
                              })
                            }
                          />
                          {ErrorPasswordConfirmation && (
                            <div
                              className="invalid-feedback"
                              style={{ display: 'block' }}
                            >
                              {ErrorPasswordConfirmation}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="privacy_consent"
                          name="privacy_consent"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          for="privacy_consent"
                        >
                          <sup>*</sup> {t('generic.user.privacy_consent')}
                          <a href="https://www.koenig-neurath.com/de/infos/datenschutzinformationen/">
                            {' '}
                            {t('generic.user.here')}
                          </a>
                          .
                        </label>
                      </div>

                      <button type="submit" className="btn btn-sm btn-primary">
                        {t('generic.user.new_password_modal_title')}
                      </button>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalNewPassword
