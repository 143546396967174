import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Helmet from 'react-helmet'

const Search = ({ searchTerm }) => {
  const [loading, setLoading] = useState(true)

  const { t, i18n } = useTranslation()

  const locale = i18n.language

  useEffect(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [locale])

  const config = {
    siteId: 'koenig-neurath',
    suggestions: {
      minChars: '2',
    },
    specialMobileSuggest: {
      enabled: true,
    },
    results: {
      group: true,
      caption: t('generic.search.results.caption'),
      moreResultsButton: t('generic.search.results.moreResultsButton'),
      noResultsText: t('generic.search.results.noResultsText'),
      queryCorrectionText: t('generic.search.results.queryCorrectionText'),
      redirectOnSingle: true,
    },

    contentGroups: {
      include: [`${locale}Pages`], // JSON array of result group names to be included in the search result
    },

    /* Smart 404 configuration */
    smart404: {
      /* Caption for 404 results */
      caption: t('generic.search.smart404.caption'),
      /* The string in the title that identifies the page as a 404 page. */
      identifier: '404',
      /* A CSS selector that points to the area in which the alternative links should be shown. */
      resultSelector: '#ss360-404',
    },
    style: {
      themeColor: '#DF542C',
      accentColor: '#DF542C',
      loaderType: 'square',
    },
    searchBox: {
      placeholder: searchTerm,
    },
  }

  const scriptConfig = `var ss360Config = ${JSON.stringify(config)}`

  if (loading) {
    return null
  }

  return (
    <>
      <Helmet>
        <script>{`${scriptConfig}`}</script>
        <script
          src="https://cdn.sitesearch360.com/v13/sitesearch360-v13.min.js"
          async
        />
      </Helmet>
      <div className="search">
        {/*  {searchConfig && (
          <SiteSearch360
            //className="form-textfield"
            siteId="koenig-neurath"
            ss360Config={searchConfig}
            //applyStyling={false}
          />
        )} */}
        <form action="#" method="get" target="_blank">
          <input
            name="q"
            className="form-textfield"
            id="searchBox"
            type="text"
            placeholder={searchTerm}
          />
          <button type="submit">
            <i className="fas fa-search" aria-hidden="true"></i>
          </button>
        </form>
      </div>
    </>
  )
}

export default Search
