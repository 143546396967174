import React, { useEffect, useState, useContext, useRef } from 'react'
import { Helmet } from 'react-helmet'
import Header from 'gatsby-theme-kn/src/components/modules/Header'
import MobileMenu from 'gatsby-theme-kn/src/components/modules/MobileMenu'
import FloatingContact from 'gatsby-theme-kn/src/components/modules/FloatingContact'
import Footer from 'gatsby-theme-kn/src/components/modules/Footer'

import 'gatsby-theme-kn/src/i18n/config'

import { useTranslation } from 'react-i18next'
import { NavContext } from 'gatsby-theme-kn/src/context/NavContext'

import ModalConfirmation from 'gatsby-theme-kn/src/components/modules/ModalConfirmation'
import ModalNewPassword from 'gatsby-theme-kn/src/components/modules/ModalNewPassword'

import useIntersectionObserver from 'gatsby-theme-kn/src/hooks/useIntersectionObserver'
import MenuButtons from 'gatsby-theme-kn/src/components/modules/MenuButtons'
import Klaro from 'gatsby-theme-kn/src/components/technical/Klaro'

const Layout = ({ children, locale, alternates, noDealerSearch }) => {
  const INITIAL_ALTERNATES_STATE = [
    {
      lang: 'de',
      full_slug: 'de/',
    },
    {
      lang: 'en',
      full_slug: 'en/',
    },
    {
      lang: 'fr',
      full_slug: 'fr/',
    },
    {
      lang: 'nl',
      full_slug: 'nl/',
    },
  ]
  const [alternateLinks, setAlternateLinks] = useState(INITIAL_ALTERNATES_STATE)

  const [mobileMenuState, setMobileMenuState] = useState(false)

  const { i18n, t } = useTranslation()

  const changeLanguage = async (language) => {
    i18n.changeLanguage(language)
  }

  const { navData } = useContext(NavContext) || { navData: {} }

  const updateAlternates = () => {
    const order = ['de', 'en', 'fr', 'nl']
    let newAlternates = []
    alternates
      .map((alternate) => ({
        // add lang to alternates
        lang: alternate.full_slug.split('/')[0],
        ...alternate,
      }))
      .filter((alternate) => {
        // get rid of cn
        if (alternate.lang !== 'cn') {
          return alternate
        }
      })
      .map((alternate) => {
        newAlternates.push(alternate)
      })

    // push currentLocation to alternates
    let currentLocation = ''
    if (typeof window !== 'undefined') {
      currentLocation = window.location.pathname.replace('/', '')
    }
    newAlternates.push({
      lang: currentLocation.split('/')[0],
      full_slug: currentLocation,
    })
    // sort alternates based on custom order
    return newAlternates.sort((a, b) => {
      // sort alternates based on predfined order
      return order.indexOf(a.lang) - order.indexOf(b.lang)
    })
  }

  useEffect(() => {
    if (alternates && alternates.length > 0) {
      setAlternateLinks(updateAlternates())
    }
    return () => {
      setAlternateLinks(INITIAL_ALTERNATES_STATE)
    }
  }, [alternates])

  const intersectionRef = useRef()

  const onScreen = useIntersectionObserver(intersectionRef, '80px')

  useEffect(() => {
    locale && i18n.language !== locale && changeLanguage(locale)
    if (typeof window !== 'undefined') {
      //window.klaro.defaultConfig.lang = language

      if (document?.location?.pathname === '/storyblok-preview/') {
        setTimeout(() => {
          if (document.querySelector('#cookie-consent')) {
            document.querySelector('#cookie-consent').style = 'display:none;'
          }
        }, 300)
      }
    }
  }, [locale, children])

  const mobileMenuClickHandler = () => {
    if (mobileMenuState === false) {
      setMobileMenuState(true)
    } else {
      setMobileMenuState(false)
    }
  }
  return (
    <>
      <Helmet
        bodyAttributes={{ class: 'test' }}
        htmlAttributes={{
          lang: locale ? locale : 'de',
        }}
      >
        <title>K+N Gatsby</title>
      </Helmet>
      <Klaro lang={locale} />

      <MobileMenu
        mainNav={navData && navData.mainNav}
        mobileMenuState={mobileMenuState}
        mobileMenuClickHandler={mobileMenuClickHandler}
        alternates={alternateLinks}
        locale={locale}
        searchTerm={t('generic.spa.search_term')}
      >
        <Header
          navData={navData?.mainNav}
          locale={locale ? locale : 'de'}
          alternates={alternateLinks}
          searchTerm={t('generic.spa.search_term')}
          loginTerm="Login"
          mobileMenuClickHandler={mobileMenuClickHandler}
        />

        <main className="main">
          <MenuButtons />
          {children}
        </main>
        <div ref={intersectionRef} />
        <Footer
          navData={navData?.footer}
          locale={locale}
          noDealerSearch={noDealerSearch}
        />
      </MobileMenu>

      <ModalConfirmation locale={locale} />
      <ModalNewPassword locale={locale} />
    </>
  )
}

export default Layout
