import React, { useState } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  apiURL,
  getUserName,
  cookieSettings,
} from '../../utils/partner_portal_spa'
import { Helmet } from 'react-helmet'

const ModalPasswordReset = (locale) => {
  const { t } = useTranslation()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [successfull, setsuccessfull] = useState(false)

  const openModal = (e) => {
    e.preventDefault()
    setIsOpen(true)
  }
  const closeModal = (e) => {
    e.preventDefault()
    setIsOpen(false)
  }

  const [FormData, setFormData] = useState({
    email: '',
  })

  const FormSubmit = (e) => {
    e.preventDefault()
    axios
      .post(apiURL('auth/password_reset'), FormData, {})
      .then((result) => {
        setsuccessfull(result.data.message)
      })
      .catch((error) => {
        // ...
      })

    return true
  }

  return (
    <div className="spa-block-full">
      {successfull && <p>{successfull}</p>}
      {!successfull && (
        <form
          data-id="partnerportal-password-reset-form"
          onSubmit={(e) => FormSubmit(e)}
        >
          <div className="mb-1">
            <label htmlFor="email">
              <sup>*</sup> {t('generic.login_menu.email')}
            </label>
            <input
              placeholder={t('generic.login_menu.email')}
              type="email"
              className="form-control"
              data-id="inputEmail"
              defaultValue={FormData.email}
              onChange={(e) =>
                setFormData({
                  ...FormData,
                  email: e.target.value,
                })
              }
            />
          </div>

          <button type="submit" className="btn btn-sm btn-primary">
            {t('generic.user.reset_password_modal_title')}
          </button>
        </form>
      )}
    </div>
  )
}

export default ModalPasswordReset
