import React, { useRef, useState, useEffect, Fragment } from 'react'
import { reveal as Menu } from 'react-burger-menu'
import Link from '../atoms/Link'
import Search from './Search'
import { useTranslation } from 'react-i18next'
import { displayNavItem } from '../../utils/partner_portal_spa'
import MobileMenuLogin from './MobileMenuLogin'
import ModalPasswordReset from 'gatsby-theme-kn/src/components/modules/ModalPasswordReset'

const MobileMenu = ({
  mainNav,
  mobileMenuState,
  mobileMenuClickHandler,
  alternates,
  children,
  searchTerm,
  locale,
}) => {
  const siteRef = useRef(null)
  const [mobileMenuFirstLevelState, setMobileMenuFirstLevelState] =
    useState(false)
  const [mobileMenuSecondLevelState, setMobileMenuSecondLevelState] =
    useState(false)

  const [mobileMenuWidthState, setMobileMenuWidthState] = useState('80%')

  const { t } = useTranslation()

  useEffect(() => {
    setMobileMenuWidthState(
      siteRef.current?.scrollWidth <= '550' ? '80%' : '440px'
    )
  }, [])

  const [changedUserState, setChangedUserState] = useState(null)

  return (
    <div id="outer-container" ref={siteRef}>
      <Menu
        menuClassName={'mobile-menu'}
        right
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        //width={'440px'}
        width={mobileMenuWidthState}
        onOpen={mobileMenuClickHandler}
        onClose={mobileMenuClickHandler}
        isOpen={mobileMenuState}
      >
        <div className="search-nav">
          <Search
            className="search-mobile"
            searchTerm={searchTerm}
            locale={locale}
          />
        </div>

        {/* Menüpunkte mit Untermenüs */}
        <ul>
          {mainNav &&
            mainNav.length > 0 &&
            mainNav.map(
              (item, index) =>
                item.component === 'main_nav_block' &&
                displayNavItem(item) &&
                (item.main_nav_group?.length > 0 ? (
                  <li
                    data-needs-session={item.needs_session}
                    key={`${item.title}-${index}`}
                  >
                    <button
                      className="btn-next"
                      onClick={() => setMobileMenuFirstLevelState(item.title)}
                    >
                      {item.title}
                    </button>
                  </li>
                ) : (
                  <li
                    data-needs-session={item.needs_session}
                    key={`${item.title}-${index}`}
                  >
                    <Link className="" link={item.path}>
                      {item.title}
                    </Link>
                  </li>
                ))
            )}
        </ul>
        {/* Untermenüs der 1. Ebene */}
        {mainNav &&
          mainNav.length > 0 &&
          mainNav.map(
            (item, mainIndex) =>
              item.main_nav_group?.length > 0 &&
              displayNavItem(item) && (
                <div
                  data-needs-session={item.needs_session}
                  className={`mobile-menu-submenu ${
                    mobileMenuFirstLevelState === item.title
                      ? 'mobile-menu-submenu-active'
                      : ''
                  }`}
                  key={`mobile-menu-menu-${item.title}-${mainIndex}`}
                >
                  <button
                    className="btn-back"
                    onClick={() => setMobileMenuFirstLevelState(null)}
                  >
                    {item.title}
                  </button>
                  <ul>
                    {item.main_nav_group?.map(
                      (firstLevelItem, firstLevelIndex) =>
                        firstLevelItem.path === '' ||
                        firstLevelItem.path === item.path ? (
                          <Fragment
                            key={`mobile-menu-submenu-${firstLevelItem.path}-${firstLevelIndex}-ov`}
                          >
                            {firstLevelItem.path === item.path && (
                              <li>
                                <Link link={firstLevelItem.path}>
                                  {t('mobile_menu.overview')}
                                </Link>
                              </li>
                            )}
                            {firstLevelItem.nav_link?.map(
                              (secondLevelItem, secindex) => (
                                <li
                                  key={`mobile-menu-submenu-${item.path}-${firstLevelIndex}-${secindex}`}
                                >
                                  <Link link={secondLevelItem.path}>
                                    {secondLevelItem.title}
                                  </Link>
                                </li>
                              )
                            )}
                          </Fragment>
                        ) : (
                          <li
                            key={`mobile-menu-submenu-${firstLevelItem.path}-${firstLevelIndex}-no-children`}
                          >
                            {firstLevelItem.nav_link?.length > 0 ? (
                              <button
                                className="btn-next"
                                onClick={() =>
                                  setMobileMenuSecondLevelState(
                                    firstLevelItem.title
                                  )
                                }
                              >
                                {firstLevelItem.title}
                              </button>
                            ) : (
                              <Link link={firstLevelItem.path}>
                                {firstLevelItem.title}
                              </Link>
                            )}
                          </li>
                        )
                    )}
                  </ul>
                </div>
              )
          )}
        {/* Sondermenü der 1 Ebene für Login-Formular */}
        <div
          className={`mobile-menu-submenu ${
            mobileMenuFirstLevelState === 'login'
              ? 'mobile-menu-submenu-active'
              : ''
          }`}
        >
          <button
            className="btn-back"
            onClick={() => setMobileMenuFirstLevelState(null)}
          >
            Login
          </button>
          <MobileMenuLogin
            t={t}
            setMobileMenuSecondLevelState={setMobileMenuSecondLevelState}
            changedUserState={changedUserState}
            setChangedUserState={setChangedUserState}
          />
        </div>
        {/* Untermenüs der 2. Ebene */}
        {mainNav &&
          mainNav.length > 0 &&
          mainNav.map(
            (item, secondIndex) =>
              item.main_nav_group?.length > 0 &&
              item.main_nav_group.map((firstLevelItem, thirdIndex) => (
                <>
                  {firstLevelItem.path !== '' &&
                    firstLevelItem.path !== item.path && (
                      <div
                        className={`mobile-menu-submenu mobile-menu-submenu-second-level ${
                          mobileMenuSecondLevelState === firstLevelItem.title
                            ? 'mobile-menu-submenu-active'
                            : ''
                        }`}
                        key={`mobile-menu-submenu-2nd-${firstLevelItem.path}-${secondIndex}-${thirdIndex}`}
                      >
                        <button
                          className="btn-back"
                          onClick={() => setMobileMenuSecondLevelState(null)}
                        >
                          {firstLevelItem.title}
                        </button>
                        <ul>
                          <li
                            key={`mobile-menu-submenu-2nd-${firstLevelItem.path}-${secondIndex}-${thirdIndex}-ov`}
                          >
                            <Link link={firstLevelItem.path}>
                              {t('mobile_menu.overview')}
                            </Link>
                          </li>
                          {firstLevelItem.nav_link?.map(
                            (secondLevelItem, fourthIndex) => (
                              <li
                                key={`mobile-menu-submenu-2nd-${secondLevelItem.path}-${secondIndex}-${thirdIndex}-${fourthIndex}`}
                              >
                                <Link link={secondLevelItem.path}>
                                  {secondLevelItem.title}
                                </Link>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                </>
              ))
          )}
        {/* Sondermenü der 2. Ebene für Passwort vergessen */}
        <div
          className={`mobile-menu-submenu mobile-menu-submenu-second-level ${
            mobileMenuSecondLevelState === 'restorepw'
              ? 'mobile-menu-submenu-active'
              : ''
          }`}
          key={`mobile-menu-submenu-2nd-restorepw`}
        >
          <button
            className="btn-back"
            onClick={() => setMobileMenuSecondLevelState(null)}
          >
            {t('generic.login_menu.password_forgotten')}
          </button>
          <ModalPasswordReset />
        </div>

        {/* Menüpunkte ohne Untermenüs */}
        <ul>
          {mainNav &&
            mainNav.length > 0 &&
            mainNav?.map(
              (item, index) =>
                !item.main_nav_group && (
                  <li key={`mobile-menu-${item.path}-${index}`}>
                    <Link className="" link={item.path}>
                      {item.title}
                    </Link>
                  </li>
                )
            )}
          <li>
            <button
              className="btn-next"
              onClick={() => setMobileMenuFirstLevelState('login')}
            >
              {changedUserState ? <i className="fal fa-user"></i> : 'Login'}
            </button>
          </li>
        </ul>
        {/* Sprachen Links */}
        <div>
          {alternates?.map((link, index) => (
            <React.Fragment key={`lang-alternates-${link.full_slug}`}>
              {index === 0 ? '' : <span className="lang-divider">|</span>}
              <Link className="btn-lang" link={`/${link.full_slug}`}>
                {link.lang.toUpperCase()}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </Menu>
      {/* Wrapper für die eigentliche Seite */}
      <div className="wrapper" id="page-wrap">
        {children}
      </div>
    </div>
  )
}

export default MobileMenu
