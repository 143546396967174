/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import useScript from '../../hooks/useScript'

// const KLARO_URL = process.env.GATSBY_KLARO_URL
// const KLARO_CONFIG_URL = process.env.GATSBY_KLARO_CONFIG_URL

const KLARO_URL = process.env.WEBSITE_URL + '/klaro.js'
const KLARO_CONFIG_URL = process.env.WEBSITE_URL + '/klaro.config.js'

const Klaro = ({ lang }) => {
  const [gtmConsented, setGtmConsented] = useState(false)
  const [marketingGtmConsented, setMarketingGtmConsented] = useState(undefined)
  const [analyticsGtmConsented, setAnalyticsGtmConsented] = useState(undefined)
  const [loaded] = useScript(KLARO_CONFIG_URL)
  const [loadedCnf] = useScript(KLARO_URL)

  // +++++++++ pushToGTM.js from EF integrated +++++++++

  const pushToGTM = (obj, delay = 0) => {
    setTimeout(() => {
      if (
        window &&
        window.dataLayer &&
        window.dataLayer.push &&
        obj &&
        typeof obj === 'object'
      ) {
        window.dataLayer.push(obj)
      }
    }, delay)
  }

  const pushToGtagTimed = (obj, delay = 0) => {
    setTimeout(() => {
      pushToGtag(...obj)
    }, delay)
  }

  function pushToGtag() {
    if (window && window.dataLayer && window.gtag && arguments) {
      window.gtag(...arguments)
    }
  }

  // +++++++++ ENDE +++++++++

  useEffect(() => {
    const language = lang || 'de'
    if (loadedCnf && loaded && window && window.klaroConfig) {
      if (process.env.NODE_ENV === 'development') {
        //window.klaroConfig.cookieDomain = "localhost"
      }

      if (window.klaro.render) {
        window.klaro.render({
          ...window.klaroConfig,
          ...{ lang: language },
        })
        let klaroManager = window.klaro.getManager(window.klaroConfig)
        if (klaroManager && klaroManager.getConsent) {
          let analyticsAllowed = klaroManager.getConsent('analytics')
          let marketingAllowed = klaroManager.getConsent('marketing')
          setGtmConsented(
            analyticsAllowed === true || marketingAllowed === true
          )
          setAnalyticsGtmConsented(analyticsAllowed)
          setMarketingGtmConsented(marketingAllowed)

          // cm-btn cm-btn-success cm-btn-info cm-btn-accept
          klaroManager.watch({
            update: (data, type) => {
              if (type === 'savedConsents') {
                window.location.reload()
              }
            },
          })
        }
      }
    }
  }, [loaded, loadedCnf])

  useEffect(() => {
    if (
      marketingGtmConsented !== undefined &&
      analyticsGtmConsented !== undefined
    ) {
      pushToGtagTimed(
        [
          'consent',
          'update',
          {
            ad_storage: marketingGtmConsented ? 'granted' : 'denied',
            ad_user_data: marketingGtmConsented ? 'granted' : 'denied',
            ad_personalization: marketingGtmConsented ? 'granted' : 'denied',
            analytics_storage: analyticsGtmConsented ? 'granted' : 'denied',
          },
        ],
        50
      )
      pushToGTM({ event: 'cookie_consent_update' }, 100)
    }
  }, [marketingGtmConsented, analyticsGtmConsented])

  // STAGING Key: N6FVPGR
  // PROD Key: WGC7GTD

  return (
    <>
      <Helmet>
        {gtmConsented === true && (
          <script type="application/javascript">
            {`
              if(window.dataLayer === undefined) {
                window.dataLayer = [];
                function gtag(){dataLayer.push(arguments);}

                gtag(
                  "consent",
                  "default",
                  {
                    ad_storage: "denied",
                    ad_user_data: "denied",
                    ad_personalization: "denied",
                    analytics_storage: "denied",
                  },
                );
              }
              `}
          </script>
        )}
        {gtmConsented === true && (
          <script type="application/javascript">
            {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-M28QGZJF');
              `}
          </script>
        )}
      </Helmet>
    </>
  )
}

export default Klaro
