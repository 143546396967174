import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {
  apiURL,
  getUserName,
  cookieSettings,
} from '../../utils/partner_portal_spa'

import { useTranslation } from 'react-i18next'

import ModalModule from 'gatsby-theme-kn/src/components/modules/ModalModule'
import useModal from 'gatsby-theme-kn/src/hooks/useModal'
import ModalPasswordReset from 'gatsby-theme-kn/src/components/modules/ModalPasswordReset'
import LoadingSpinner from 'gatsby-theme-kn/src/components/modules/LoadingSpinner'

// GET-Params
import queryString from 'query-string'
let params = {}
if (typeof window !== 'undefined') {
  params = queryString.parse(location.search)
}

const Login = ({
  activeMenuState,
  loginTerm,
  t,
  toggleMenu,
  changedUserState,
  setChangedUserState,
}) => {
  const cookies = new Cookies()

  const loginCookiePresent = () => {
    return cookies.get('auth_token')?.length > 0
  }

  const [LoggedIn, setLoggedIn] = useState(loginCookiePresent)
  const [LoginFormFailed, setLoginFormFailed] = useState(false)
  const [LoginFormSending, setLoginFormSending] = useState(false)
  const [LoginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
    remember_me: 0,
  })

  const LoginFormSubmit = (e) => {
    e.preventDefault()
    axios
      .post(apiURL('auth/login'), LoginFormData, {})
      .then((result) => {
        cookies.set('auth_token', result.data.auth_token, cookieSettings())
        cookies.set(
          'user_roles',
          result.data.roles
            .join(',')
            .replace(/media_upload_category_/g, 'mdb_uc_'),
          cookieSettings()
        )
        cookies.set('user_name', result.data.name, cookieSettings())
        cookies.set(
          'wbk_auth_token',
          result.data.wbk_auth_token,
          cookieSettings()
        )
        cookies.set(
          'wbk_auth_userdata',
          result.data.wbk_auth_userdata,
          cookieSettings()
        )
        cookies.set('nf_jwt', result.data.netlify_jwt, cookieSettings())

        setLoggedIn(true)
        setLoginFormFailed(false)
        setChangedUserState(true)
        toggleMenu(e, 'login')

        if (typeof window !== 'undefined' && params?.redirect_to.length > 1) {
          window.location.href = params?.redirect_to
        }
      })
      .catch((error) => {
        setLoginFormFailed(true)
      })

    return true
  }

  const Logout = (e) => {
    cookies.remove('auth_token', cookieSettings())
    cookies.remove('user_roles', cookieSettings())
    cookies.remove('user_name', cookieSettings())
    cookies.remove('environment', cookieSettings())
    cookies.remove('wbk_auth_token', cookieSettings())
    cookies.remove('wbk_auth_userdata', cookieSettings())
    cookies.remove('nf_jwt', cookieSettings())

    setLoggedIn(false)
    setChangedUserState(false)
    setLoginFormFailed(false)
    return true
  }

  const [modalState, setModalState] = useState(false)
  const closeModal = () => {
    setModalState(false)
  }

  return (
    <>
      <ModalModule
        isOpen={modalState}
        closeModal={closeModal}
        title={t('generic.user.reset_password_modal_title')}
      >
        <ModalPasswordReset />
      </ModalModule>

      <a
        className={`nav-link has-childs ${
          activeMenuState === 'login' && 'active'
        }`}
        target="_blank"
        rel="noopener"
        onClick={(e) => toggleMenu(e, 'login')}
        onBlur={() => menuBlurHandler()}
      >
        {!LoggedIn && <span>{loginTerm}</span>}
        {LoggedIn && <i className="fal fa-user"></i>}
      </a>

      <div
        className={`meta-nav-menu ${activeMenuState === 'login' && 'show'}`}
        data-id="login-menu"
      >
        {!LoggedIn && (
          <form
            data-id="partnerportal-login-form"
            onSubmit={(e) => LoginFormSubmit(e)}
          >
            {LoginFormFailed && (
              <p className="alert alert-danger">
                <small>{t('generic.login_menu.error')}</small>
              </p>
            )}
            <div className="mb-1">
              <input
                placeholder={t('generic.login_menu.email')}
                type="email"
                className="form-control"
                data-id="inputEmail"
                defaultValue={LoginFormData.email}
                onChange={(e) =>
                  setLoginFormData({
                    ...LoginFormData,
                    email: e.target.value,
                  })
                }
              />
            </div>
            <div className="mb-1">
              <input
                placeholder={t('generic.login_menu.password')}
                type="password"
                className="form-control"
                data-id="inputPassword"
                defaultValue={LoginFormData.password}
                onChange={(e) =>
                  setLoginFormData({
                    ...LoginFormData,
                    password: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input"
                  data-id="inputRememberMe"
                  defaultValue={LoginFormData.remember_me}
                  onChange={(e) =>
                    setLoginFormData({
                      ...LoginFormData,
                      remember_me: e.target.checked ? 1 : 0,
                    })
                  }
                />
                {t('generic.login_menu.remember_me')}
              </label>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">
              {t('generic.login_menu.login')}
            </button>
            <br />
            <a href="#" onClick={(e) => setModalState(true)} className="">
              {t('generic.login_menu.password_forgotten')}
            </a>
            <a
              className="info-link"
              href={t('generic.spa.paths.partner_portal_info')}
            >
              {t('generic.login_menu.info')} &nbsp;
              <i className="fal fa-chevron-right"></i>
            </a>
          </form>
        )}
        {LoggedIn && (
          <div data-id="partnerportal-logged-in-menu">
            <h4>
              {t('generic.login_menu.welcome')} {getUserName()}
            </h4>
            <a
              data-id="partnerportal-logout-btn"
              className="btn btn-sm btn-primary"
              onClick={(e) => Logout(e)}
            >
              {t('generic.login_menu.logout')}
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default Login
