import React from 'react'
import Link from '../atoms/Link'

import { useTranslation } from 'react-i18next'

const MenuButtons = () => {
  const { t } = useTranslation()
  return (
    <div className="menu-buttons">
      <Link
        link={t('generic.contact_link')}
        className="menu-buttons--single-bar"
        href="#"
      >
        <div className="menu-buttons--single-button">
          <i className="fa fa-at" />
        </div>
        <span className="menu-buttons--text">{t('generic.contact')}</span>
      </Link>
      <Link
        link={'https://www.instagram.com/koenig_neurath_ag/'}
        className="menu-buttons--single-bar"
        href="#"
      >
        <div className="menu-buttons--single-button">
          <i className="fab fa-instagram"></i>
        </div>
        <span className="menu-buttons--text">Instagram</span>
      </Link>
      <Link
        link={'https://de.linkedin.com/company/k-nig-neurath'}
        className="menu-buttons--single-bar"
        href="#"
      >
        <div className="menu-buttons--single-button">
          <i className="fab fa-linkedin"></i>
        </div>
        <span className="menu-buttons--text">LinkedIn</span>
      </Link>
      <Link
        link={'https://www.facebook.com/koenig.neurath/'}
        className="menu-buttons--single-bar"
        href="#"
      >
        <div className="menu-buttons--single-button">
          <i className="fab fa-facebook"></i>
        </div>
        <span className="menu-buttons--text">Facebook</span>
      </Link>
      <Link
        link={'https://www.youtube.com/user/KoenigNeurathAG'}
        className="menu-buttons--single-bar"
        href="#"
      >
        <div className="menu-buttons--single-button">
          <i className="fab fa-youtube"></i>
        </div>
        <span className="menu-buttons--text">YouTube</span>
      </Link>
      {/* <a className="menu-buttons--single-bar" href="#">
        <div className="menu-buttons--single-button">
          <i className="fa fa-shopping-cart" />
        </div>
        <span className="menu-buttons--text" href="#">
          Zum Shop
        </span>
      </a> */}
    </div>
  )
}

export default MenuButtons
