import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

const DealerSearch = () => {
  const [value, setValue] = useState('')

  const { t } = useTranslation()

  const handleInput = (event) => setValue(event.target.value)

  const handleSubmit = (event) => {
    event.preventDefault()
    const path = t('generic.spa.paths.dealer_map')
    value && value !== ''
      ? navigate(path, {
          state: { value, byInput: true },
        })
      : navigate(path, {
          state: { byCoords: true },
        })
  }

  return (
    <div className="dealer-map-search">
      <span className="h3">{t('dealer_search.title')}</span>
      <form onSubmit={handleSubmit} className="dealer-map-search-form">
        <input
          className="dealer-map-search-form-input"
          type="text"
          name="location_query"
          placeholder={t('dealer_search.placeholder')}
          value={value}
          onChange={handleInput}
        />
        {value && value !== '' ? (
          <div className="dealer-map-search-form-button-wrapper">
            <button
              type="submit"
              className="dealer-map-search-form-search-button"
            >
              <span>{t('dealer_search.search_address')}</span>
            </button>
          </div>
        ) : (
          <div className="dealer-map-search-form-button-wrapper">
            <button
              type="submit"
              className="dealer-map-search-form-search-button"
            >
              <span>{t('dealer_search.detect_location')}</span>
            </button>
          </div>
        )}
      </form>
    </div>
  )
}

export default DealerSearch
