import React from 'react'
import { useTranslation } from 'react-i18next'

const LoadingSpinner = () => {
  const { t } = useTranslation()
  return (
    <div className="spa-wait-block">
      <div className="spa-wait-block-text">
        <div className="spa-wait-block-text-content">
          <i className="fal fa-spinner-third fa-spin"></i>
          <p>{t('generic.spa.data_loading')}</p>
        </div>
      </div>
    </div>
  )
}

export default LoadingSpinner
