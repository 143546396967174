import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { useTranslation } from 'react-i18next'
import useAxiosPartnerPortal from 'gatsby-theme-kn/src/hooks/useAxiosPartnerPortal'
import {
  apiURL,
  getUserName,
  cookieSettings,
} from '../../utils/partner_portal_spa'

import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'

const ModalConfirmation = (locale) => {
  const { t } = useTranslation()
  const [modalIsOpen, setIsOpen] = useState(false)
  const [successfull, setsuccessfull] = useState(false)

  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }

  const location = useLocation()
  const params = queryString.parse(location.search)

  const [partnerPortalUrl, setPartnerPortalUrl] = useState(null)
  const { data, loading, error } = useAxiosPartnerPortal(
    partnerPortalUrl,
    { validation_token: params.validation_token },
    'post'
  )

  useEffect(() => {
    if (params.user_action === 'confirm_email' && !successfull) {
      openModal()
      setPartnerPortalUrl(apiURL('auth/confirm_email_address'))
      return true
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="spa-modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,.5)',
            zIndex: '1060',
          },
        }}
      >
        <Helmet bodyAttributes={{ class: 'overflow-hidden' }}></Helmet>
        <div
          id="spa-modal"
          className="spa-modal modal d-block"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="spa-modal-label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="spa-modal-label">
                  {t('generic.user.user_modal_title')}
                </h4>
                <button
                  type="button"
                  className="btn btn-close"
                  data-dismiss="modal"
                  onClick={closeModal}
                ></button>
              </div>
              <div className="modal-body">
                <>
                  {data && <p>{data.message}</p>}
                  {error && <p>{error.message}</p>}
                </>
              </div>
              {/* <div className="modal-footer"></div> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ModalConfirmation
